import React, { useContext, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { openDialog, closeDialog } from "../../../features/dialogBox/dialogBoxSlice";
import { useSelector, useDispatch } from 'react-redux';
import ApiService from '../../../utils/ApiService';
import MyContext from '../Mycontext';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
const FolderDashboardKM = ({ parentId }) => {
  let { id } = useParams();
  const [folderName, setFolderName] = useState('')
  const [folderNameErr, setFolderNameErr] = useState('')
  const [flag, setFlag] = useState(false)
  const [submitFlag, setSubmitFlag] = useState(false)
  const methodsAndState = useContext(MyContext);
  const dispatch = useDispatch();
  const closeDialogPublish = (id) => {
    dispatch(closeDialog(id));
  }

  const validateFolderName = (name) => {
    // Regular expression to check for special characters
    const regex = /[\\/:;*?"<>|]/;
    return !regex.test(name); // Returns true if no special characters are found
  };

  // Update setFolderName to validate input
  // const handleFolderNameChange = (e) => {
  //   const newName = e.target.value.replace(/^\s*/, '');
  //   if (validateFolderName(newName)) {
  //     // if (newName == "") {
  //     //   setFolderName('');
  //     // } else {
  //     // }
  //     setFolderName(newName);
  //     setFolderNameErr('')
  //     setSubmitFlag(false)
  //   } else {
  //     setFolderName('');
  //     setSubmitFlag(true)
  //     setFolderNameErr("Folder name can't contain any of following characters:  \\ / : * ? \" < > |")
  //   }
  // };

  const handleFolderNameChange = (e) => {
    const newName = e.target.value.trim(); 
    const containsInvalidChars = /[\/:;*?"<>|]/.test(newName); 

    if (!containsInvalidChars) {
        setFolderName(newName);
        setFolderNameErr('');
        setSubmitFlag(false);
    } else {
        setFolderName('');
        setSubmitFlag(true);
        setFolderNameErr("tags name can't contain any of the following characters: \\ / : ;* ? \" < > |");
    }
};



  const saveFolder = () => {
    if (folderName) {
        // const paramsid = typeof methodsAndState.selectedId.id === 'string' && methodsAndState.selectedId.id.includes('_') ? methodsAndState.selectedId.id.split('_')[1] : methodsAndState.selectedId.id;
        const paramsid = typeof id === 'string' && id.includes('_') ? id.split('_')[1] : id
        var formData = {
          folderName: folderName.trim(),
          parentFolder: paramsid == undefined ? "0" : paramsid
          // parentFolder: methodsAndState.selectedItems.size >= 1 ? "0" : paramsid
        }
        setSubmitFlag(false)
        setFlag(true)
        ApiService.post('folderpermission', formData).then(res => {
          let response = res.data.data ? res.data.data : res.data
          setFlag(false)
          if (response?.detail) {
            methodsAndState.setSnackBar({ dataLoad: true, message: response?.detail, severity: "error" })
          } else {
            methodsAndState.setSnackBar({ dataLoad: true, message: "New folder has been successfully created.", severity: "info" })
            let newFolderData = methodsAndState.dashboardList.filter(v => {
              return true;
            })
            const newObject = {
              name: folderName,
              id: "folder_" + response.id,
              parentFolder: methodsAndState.selectedId.name == "" ? "root" : methodsAndState.selectedId.name,
              parentFolderId: methodsAndState.selectedId.id,
              type: "directory"
            };
            // const newDataWithNewObject = [...methodsAndState.dashboardList, newObject];
            const newDataWithNewObject = [...methodsAndState.dashboardList];
            newDataWithNewObject.splice(0, 0, newObject);
            methodsAndState.setDashboardList(newDataWithNewObject);
            methodsAndState.updateMetaData( 1 );
            closeDialogPublish('choiceDialog')
          }
        }).catch((error) => {
          setFlag(false)
          methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })
    } else {
      setSubmitFlag(true)
      setFolderNameErr("Field is required")
    }
  }


  return (
    <Box>
      <DialogTitle id="dialog-title" sx={{ backgroundColor: 'var(--color-lightblue)', padding: 'var(--padding-none)' }}>
        <Box sx={{ p: '0.25rem 1rem' }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Create Folder </Typography>
            </Box>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: '16px 24px' }}>
        <Box sx={{ width: 500, maxWidth: '100%', }}>
          <TextField value={folderName} error={submitFlag} helperText={folderNameErr} fullWidth label="New Folder" id="newFolder" onChange={handleFolderNameChange} />
        
        </Box>
      </DialogContent >
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button variant="text" onClick={() => closeDialogPublish('choiceDialog')} disabled={!flag ? false : true}>
          CANCEL
        </Button>
        {flag == false ? <Button variant="contained" onClick={saveFolder}>
          CREATE
        </Button>
          :
          <Button variant="contained">
            <CircularProgress color={'success'} size="20px" />
          </Button>
        }
      </DialogActions>
    </Box>
  )
}

export default FolderDashboardKM