import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Breadcrumbs, CircularProgress, Grid, Radio, Skeleton } from '@mui/material';
import MyContext from '../Mycontext';
import ApiService from '../../../utils/ApiService';
import folder from '../../../assets/images/KM-Manager/FolderMoveIcon.svg';
import { closeDialog } from '../../../features/dialogBox/dialogBoxSlice';
import { useSelector, useDispatch } from 'react-redux';
import MenuBreadcrumb from '../MenuBreadcrumb';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2563EB',

    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000000',
        fontWeight: '400',
        boxShadow: '-1px 0px 6px #aaa',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #2563EB',
        borderRadius: 2,
    },
}));
const MoveDialog = () => {
    const methodsAndState = useContext(MyContext);
    const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
    const [folderList, setFolderList] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [loaderValue, setLoaderValue] = useState(false);
    const [breadCrumb, setBreadCrumb] = useState([{ id: '0', name: "File Library" }]);
    const [flag, setFlag] = useState(false)
    const dispatch = useDispatch();
    const searchList = useSelector((state) => (state.search));
    const isTagsPage = window.location.pathname.indexOf("tags-folder") != -1; 

    const buttonSX = {
        '&': { color: '#00000087', cursor: "pointer", paddingLeft: "10px" },
        '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087', fontSize: "15px" }
    }
    const closeDialogPublish = (id) => {
        dispatch(closeDialog(id));
    }
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const folderListApi = (id) => {
        setLoaderValue(true)
        ApiService.get('folderpermission', {}, id).then(res => {
            setLoaderValue(false)
            let reverseData = res?.data?.data.filter(v => v.type === 'directory');
            setFolderList(reverseData)
        }).catch((error) => {
            methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })
    }
    useEffect(() => {
        folderListApi('0')
    }, [])

    const openFolder = (data) => {
        setBreadCrumb([...breadCrumb, data])
        folderListApi(data.id)
    };

    const movefilehandler = () => {
        const paramsid = typeof selectedData.id === 'string' && selectedData.id.includes('_') ? selectedData.id.split('_')[1] : selectedData.id;
        setFlag(true)
        let params = {
            'parentFolder': selectedValue
        }

        ApiService.post('moveFile', params, paramsid).then(res => {
            setFlag(false)
            if (searchList.searchList.length == 0 && searchList.searchValue == "" && !isTagsPage){
                let moveData = methodsAndState.dashboardList.filter(v => selectedData.id !== v.id)
                methodsAndState.setDashboardList(moveData)
                methodsAndState.updateMetaData( -1 );
            }
            closeDialogPublish('moveDialog');
            const folderToName = folderList.filter(v => v.id == selectedValue)?.[0]?.name || 'Customer Experience';
            methodsAndState.setSnackBar({ dataLoad: true, message: selectedData?.name + " has been successfully moved to '" + folderToName + "' Folder. ", severity: "info" })
            methodsAndState.setSelectedItems(new Set([]))
        }).catch((error) => {
            setFlag(false)
            methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
            console.error('error');
        })
    }

    const openFolderBreadcrumb = (data) => {
        let breadArray = breadCrumb.findIndex(d => d.id == data.id);
        let popBreadValue = breadCrumb.slice(0, breadArray + 1)
        setBreadCrumb(popBreadValue)
        folderListApi(data.id)
    }

    const breadCrumbCom = () => {
        return (
            <Breadcrumbs aria-label="breadcrumb">
                {breadCrumb.length > 2 ? <MenuBreadcrumb move={true} breadCrumb={breadCrumb} openFolderBreadcrumb={openFolderBreadcrumb} /> : ""}
                {breadCrumb.slice(-2).map((v, key) => (
                    <Box key={key}
                        onClick={() => openFolderBreadcrumb(v)}
                        sx={{ cursor: breadCrumb.indexOf(v) === breadCrumb.length - 1 ? 'default' : 'pointer', zIndex: '99999' }}>
                        <Typography key={v.id} variant='span' >{v.name}</Typography>

                    </Box>
                ))}
            </Breadcrumbs>
        )
    }

    return (
        <Box>
            <DialogTitle id="dialog-title" sx={{ backgroundColor: 'var(--color-lightblue)', padding: 'var(--padding-none)' }}>
                <Box sx={{ p: '0.55rem 2rem' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Move {" "}
                                <HtmlTooltip title={selectedData?.name}>
                                    <Typography variant='span'>
                                        "{selectedData?.name.length > 35
                                            ? selectedData?.name.substring(0, 35) + '...'
                                            : selectedData?.name}"
                                    </Typography>
                                </HtmlTooltip>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: '16px 24px', height: "300px", width: 500, overflowX: "hidden" }}>
                {breadCrumb.length > 1 ?
                    <Box >
                        {breadCrumbCom()}
                    </Box>
                    :
                    null
                }

                {loaderValue ?
                    <Box>
                        {['', '', '', '', '', '', ''].map((d, key) => {
                            return (
                                <Box sx={{ width: 500, py: 1 }} key={key} >

                                    <Skeleton animation="wave" />
                                </Box>
                            );
                        })}
                    </Box>
                    :
                    folderList.map((d, i) => (
                        <Box key={i} sx={{ display: 'flex', cursor: "pointer", padding: '5px 0px', borderBottom: "1px solid rgba(0, 0, 0, 0.08)", alignItems: 'center', width: 500, maxWidth: '100%', }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}  >
                                <Radio checked={selectedValue == d.id}
                                    onChange={handleRadioChange}
                                    value={d.id} />
                            </Box>
                            <Box onClick={() => openFolder(d)} sx={{ display: 'flex', width: 500, alignItems: 'center', textTransform: "capitalize" }}  >

                                <img src={folder} alt="File" />
                                <Typography width={"100%"} variant='span' px={1} >
                                    {d.name != undefined ? <HtmlTooltip title={d.name}>
                                        <Typography variant='span'>
                                            {d.name.length > 35
                                                ? d.name.substring(0, 35) + '...'
                                                : d.name}
                                        </Typography>
                                    </HtmlTooltip> : "--"}
                                </Typography>
                                <Box sx={buttonSX} >
                                    <ArrowForwardIosIcon />
                                </Box>
                            </Box>
                        </Box>
                    ))
                }
            </DialogContent >
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button variant="text" onClick={() => closeDialogPublish('moveDialog')} >
                    CANCEL
                </Button>
                {flag == false ?
                    <Button variant="contained" disabled={selectedValue ? false : true} onClick={movefilehandler}>
                        MOVE
                    </Button>
                    :
                    <Button variant="contained">
                        <CircularProgress color={'success'} size="20px" />
                    </Button>
                }
            </DialogActions>
        </Box >
    )
}
export default MoveDialog;