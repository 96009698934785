import React, { useContext } from "react";
import { Box, Button, Stack } from "../../../utils";
import DrawerContext from "./DrawerContext";

function DrawerFooter(props) {
  const drawerContextData = useContext(DrawerContext);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: "100%",
        position: "absolute",
        bottom: "0",
        mt: "4rem",
        height: "4rem",
        zIndex: "1000",
      }}
    >
      <Stack sx={{ mx: "1rem", pt: "1rem" }} direction="row" spacing={1}>
        {/* {methodsAndState.selectedItems.size && !methodsAndState.editFlag.flag && methodsAndState.editFlag.value == "edit" ?
            <Button variant="contained" size="small" onClick={editHandler} startIcon={<EditIcon />} >EDIT DETAILS</Button> :

            flag == false ? <Button variant="contained" size="small" onClick={createFile} disabled={isSubmitDisabled} >SUBMIT</Button>
              :
              <Button variant="contained">
                <CircularProgress color={'success'} size="20px" />
              </Button>
          } */}
        {drawerContextData?.footerAction}
        <Button
          variant="text"
          size="small"
          onClick={(e) => drawerContextData?.toggleDrawerStatusHandler(false)}
        >
          CANCEL
        </Button>
      </Stack>
    </Box>
  );
}

export default DrawerFooter;
