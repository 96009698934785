import React, { useContext, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { closeDialog } from "../../../features/dialogBox/dialogBoxSlice";
import { useDispatch, useSelector } from 'react-redux';
import ApiService from '../../../utils/ApiService';
import MyContext from '../Mycontext';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { Alert } from '@mui/material';
const RenameDialog = ({ parentId }) => {
    const [folderRename, setFolderRename] = useState('')
    const methodsAndState = useContext(MyContext);
    const [flag, setFlag] = useState(false)
    const dispatch = useDispatch();
    const userList = useSelector((state) => state?.user?.userListName);

    const closeDialogPublish = (id) => {
        dispatch(closeDialog(id));
    }
    // Filtering the dashboardList to find the matching object and setting the default value
    const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
    const defaultValue = selectedData ? selectedData.name : '';
    const originalString = defaultValue;
    const lastDotIndex = originalString.lastIndexOf(".");
    const extension = lastDotIndex !== -1 ? originalString.slice(lastDotIndex + 1) : "";
    const [nameErr, setNameErr] = useState(false)

    const rename = () => {
        try{
            let renameData = methodsAndState.dashboardList.map(v => {
                let copy = JSON.parse(JSON.stringify(v));
                if (methodsAndState.selectedItems.has(copy.id) && selectedData?.type != "directory") {
                    copy.name = folderRename + "." + extension;
                    copy.modifyBy = {id: userList?.userId, name: userList['userName'], lastName: userList['lastName']}
                } else {
                    if (methodsAndState.selectedItems.has(copy.id)) {
                        copy.name = folderRename
                    }
                }
                return copy;
            })
            methodsAndState.setDashboardList(renameData)
            methodsAndState.setSelectedItems(new Set([]))
        } catch(e) {
            console.log({e})
        }
        closeDialogPublish('renameDialog')
    }

    const renameFileFolder = () => {
        const matchparamsidingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
        let endPoint = matchparamsidingData[0].type
        if (folderRename) {
            setFlag(true)
            let paramsid = typeof matchparamsidingData[0].id === 'string' && matchparamsidingData[0].id.includes('_') ? matchparamsidingData[0].id.split('_')[1] : matchparamsidingData[0].id;
            if(methodsAndState?.isRecentFiles){
                paramsid = selectedData.mediaId;
            }
            if (endPoint == "directory") {
                let updateData = {}
                updateData['name'] = folderRename ? folderRename : defaultValue;
                updateData['modifiedBy'] = userList?.userId;
                ApiService.put('folderpermission', updateData, paramsid).then(res => {
                    if (res?.data?.detail) {
                        methodsAndState.setSnackBar({ dataLoad: true, message: res?.data?.detail, severity: "error" })
                    } else {
                        methodsAndState.setSnackBar({ dataLoad: true, message: "New folder has been successfully renamed.", severity: "info" })
                        rename()
                    }
                    setFlag(false)
                }).catch((error) => {
                    setFlag(false)
                    methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
                    console.log({ error })
                })
            } else {
                var formData = {
                    fileName: folderRename ? folderRename + "." + extension : defaultValue,
                    userId: userList?.userId
                }
                // fileManager
                ApiService.post('renamefileManager', formData, paramsid).then(res => {
                    console.log({res})
                    methodsAndState.setSnackBar({ dataLoad: true, message: res.data.title ?? "New file has been successfully renamed.", severity: "info" })
                    setFlag(false)
                    if(res.data.status != 400){
                        rename();
                    }
                }).catch((error) => {
                    setFlag(false)
                    methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
                    console.log({ error })
                })
            }
        } else {
            setNameErr(true)
        }
    }

    const validateFolderName = (name) => {
        // Regular expression to check for special characters
        const regex = /[\\/:;*?"<>|]/;
        return !regex.test(name); // Returns true if no special characters are found
    };

    function onchangeRenameHandler(e) {
        // if (e.target.value == defaultValue) {
        //     setNameErr(true)
        // } else {
        //     setNameErr(false)
        // }
        // setFolderRename(e.target.value)


        const newName = e.target.value;
        if (validateFolderName(newName)) {
            if (newName.trim() == "") {
                setFolderRename('');
            } else {
                setFolderRename(newName);
            }
            setNameErr('')
            // setSubmitFlag(false)
        } else {
            setFolderRename('');
            // setSubmitFlag(true)
            setNameErr("Folder name can't contain any of following characters:  \\ / : * ? \" < > |")
        }
    }


    return (
        <Box pb="1rem">
            <DialogTitle id="dialog-title" sx={{ backgroundColor: 'var(--color-lightblue)', padding: 'var(--padding-none)' }}>
                <Box sx={{ p: '0.25rem 1rem' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Rename {selectedData?.type == "directory" ? "Folder" : "File"} </Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: '16px 24px' }}>
                <Box sx={{ width: 500, maxWidth: '100%', }}>
                    <TextField fullWidth label="Rename" id="renameFolder"
                        defaultValue={selectedData?.type == "directory" ? defaultValue : defaultValue.split('.')[0]}
                        onChange={onchangeRenameHandler}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{selectedData?.type == "directory" ? null : extension}</InputAdornment>,
                        }}
                    />
                    {nameErr ? <Alert variant="none" sx={{ color: "red" }} severity="error">
                        {"Name can't contain any of following characters:  \\ / : ;* ? \" < > |"}
                    </Alert> : ""}
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button variant="text" onClick={() => closeDialogPublish('renameDialog')} disabled={!flag ? false : true}>
                    CANCEL
                </Button>
                {flag == false ? <Button variant="contained" disabled={folderRename == '' ? true : nameErr} onClick={renameFileFolder}>
                    SAVE
                </Button>
                    :
                    <Button variant="contained">
                        <CircularProgress color={'success'} size="20px" />
                    </Button>
                }
            </DialogActions>
        </Box>
    )
}
export default RenameDialog;