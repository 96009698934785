import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Box,
  Typography,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "../../../features/drawers/drawerSlice";
import ApiService from "../../../utils/ApiService";
import MyContext from "../Mycontext";
import { getAppToken } from "../../../_helper/secureToken";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { ComboBox, EnhanceDrawer } from "../../../libs";
import { FileUpload } from "../../../libs";


function createSlug(string) {
  return string
    .toLowerCase() // Convert to lowercase
    .trim() // Trim whitespace from both sides
    .replace(/[^a-z0-9\s-]/g, "") // Remove all non-alphanumeric characters except spaces and hyphens
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
}

function findCategoryIndex(categories, identifier) {
  return categories.findIndex((d) => d?.categoryName === identifier);
}

function createCategoryObject(identifier, value) {
  return {
    categoryName: identifier,
    value: { id: value.id, subCategoryName: value.subCategoryName, label: value.subCategoryName },
  };
}

function getDefaultCategoryValue(category, categoryComponentSelectedValue) {
  // console.debug("FileDashboardKM : fileDetail : category : ",category);
  // console.debug("FileDashboardKM : fileDetail : categoryComponentSelectedValue : ",categoryComponentSelectedValue);
  if (!category?.categoryName) return [];
  
  const slug = createSlug(category.categoryName);
  // console.debug("FileDashboardKM : fileDetail : slug : ",slug);
  const filteredValue = categoryComponentSelectedValue.filter(item => { 
    if(item.categoryName === slug)
      return item
    }
  );
  // console.debug("FileDashboardKM : fileDetail : filteredValue : ",filteredValue);

  return ((filteredValue.length && filteredValue[0]?.value !== undefined)? filteredValue[0].value : null);
}


function updateCategoryComponentSelectedValue(
  tempCategoryComponentSelectedValue,
  identifier,
  value
) {
    // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue Start : ");
    // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue : value : ",value);
    // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue :identifier : ",identifier);
    // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue :tempCategoryComponentSelectedValue : ",tempCategoryComponentSelectedValue);

  const categoryIndex = findCategoryIndex(tempCategoryComponentSelectedValue, identifier);
      // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue : categoryIndex : ",categoryIndex);
if (categoryIndex < 0) {
  // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue : if Block : tempCategoryComponentSelectedValue : ",tempCategoryComponentSelectedValue);
  const newCategory = createCategoryObject(identifier, value);
  tempCategoryComponentSelectedValue = [...tempCategoryComponentSelectedValue, newCategory];
  } else {
    // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue : else Block : value : ",tempCategoryComponentSelectedValue);
    const updatedCategories = [...tempCategoryComponentSelectedValue];
    updatedCategories[categoryIndex].value = value;
    // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue : tempCategoryComponentSelectedValue : ",tempCategoryComponentSelectedValue);
    tempCategoryComponentSelectedValue = [...updatedCategories];
  }
  // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue : updated tempCategoryComponentSelectedValue : ",tempCategoryComponentSelectedValue);
  // console.debug("FileDashboardKM : fileDetail : updateCategoryComponentSelectedValue End : ");

  return tempCategoryComponentSelectedValue;
}


function FileDashboardKM({ parentId, props }) {
  const methodsAndState = useContext(MyContext);
  let { id } = useParams();
  const [flag, setFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileDescriptions, setFileDescriptions] = useState("");
  const [fileName, setFilename] = useState("");
  const [tagOptionList, setTagOptionsList] = useState([]);
  const [tagCharacterLimitErr, setTagCharacterLimitErr] = useState(false);
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const [categoryComponent, setCategoryComponent] = useState(null);
  const [categoryComponentSelectedValue, setCategoryComponentSelectedValue] =
    useState([]);
  const categoryListData = useSelector((state) => state.category);
  const [fileUploadData, setfileUploadData] = useState();
  console.debug("FileDashboardKM : component : categoryComponentSelectedValue : ", [
    ...categoryComponentSelectedValue,
  ]);

  const disabledFields =
    methodsAndState.editFlag.flag || methodsAndState.editFlag.value == "create";

  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  const onDrawerActiveStatusChangeHandler = (activeStatus) => {
    if(activeStatus==false){
      dispatch(closeDrawer(parentId));
    }
  };

  const handleFilenameChange = (e) => {
    if (e.target.name == "filename") {
      setFilename(e.target.value);
    }
    if (e.target.name == "filedescription") {
      setFileDescriptions(e.target.value);
    }
  };

  const isSubmitDisabled = false;
  const editHandler = () => {
    methodsAndState.setEditFlag({ flag: true, value: "edit" });
  };

  const fileChangeHandler = (fileData) => {
    setfileUploadData(fileData);
  };
  const createFile = () => {
    // console.debug("FileDashboardKM : createFile : submit handler : selectedTagOptions : ",selectedTagOptions);
    // console.debug("FileDashboardKM : createFile : submit handler : categoryComponentSelectedValue : ",categoryComponentSelectedValue);

    let formData = new FormData();
    formData.append("app", getAppToken());
    // if (fileName) {
    //   formData.append('fileName', fileName);
    // }
    if (fileUploadData) {
      formData.append("file", fileUploadData[0]);
    }
    if (fileDescriptions) {
      formData.append("fileDescription", fileDescriptions);
    }

    if (selectedTagOptions?.length) {
      selectedTagOptions.forEach((item) => {
        if (typeof item === "string" || item instanceof String) {
          formData.append("filetag[]", item);
        } else {
          formData.append("filetag[]", item?.label);
        }
      });
    }

    if (categoryComponentSelectedValue.length) {
      categoryComponentSelectedValue.forEach((d) => {
        formData.append("subCategoryId[]", d?.value?.id);
      });
    }

    // formData.append('userId', userList?.userId);

    if (methodsAndState.editFlag.value == "create") {
      const paramsid = id == undefined ? "0" : id;
      formData.append("parentFolder", paramsid);
    }
    else {
      console.log(methodsAndState.selectedItems,Array.from(methodsAndState.selectedItems),(Array.from(methodsAndState.selectedItems)?.[0]))
      var params = (Array.from(methodsAndState.selectedItems)?.[0]);
      if(methodsAndState?.isRecentFiles){
        const selectedData = methodsAndState.dashboardList.find((item) => methodsAndState.selectedItems.has(item.id));
        params = selectedData.mediaId;
      } else {
        params = (typeof params == 'string') ? params.split("_")?.[1] : params;
      }
    }

    //  Upload data to service
    setFlag(true);
    let fileDashboard = methodsAndState.dashboardList.filter((v) =>
      methodsAndState.selectedItems.has(v.id)
    );
    ApiService.post("fileManager", formData, params)
    // ApiService.post("fileManager", formData)
      .then((res) => {
        let data = res.data.data ? res.data.data : res.data;
        // console.debug({ data, res });
        setFlag(false);
        if (data?.status == "400") {
          const message = data.title;
          methodsAndState.setSnackBar({
            dataLoad: true,
            message: message,
            severity: "error",
          });
          return;
        }
        if (methodsAndState.editFlag.value == "create") {
          let newFolderData = methodsAndState.dashboardList.filter((v) => {
            return true;
          });
          const newObject = {
            name: data.name,
            id: "file_" + data.id,
            parentFolder:
              methodsAndState.selectedId.name == ""
                ? "root"
                : methodsAndState.selectedId.name,
            parentFolderId: data.parentFolder,
            type: data.type == null ? fileDashboard[0].type : data.type,
            description: data.fileDescription,
            size: fileUploadData?.[0]?.size,
            tags: [],
            // tags: selectedTagArray.map((label, index) => {
            //   return {
            //     id: index,
            //     name: { en: label },
            //   };
            // }),
          };
          const newDataWithNewObject = [
            ...methodsAndState.dashboardList,
            newObject,
          ];
          methodsAndState.setDashboardList(newDataWithNewObject);
          methodsAndState.updateMetaData(1);
        } else {
          let renameData = methodsAndState.dashboardList.filter((v) => {
            if (methodsAndState.selectedItems.has(v.id)) {
              v.name = data.name;
            }
            return v;
          });
          methodsAndState.setDashboardList(renameData);
        }
        const message =
          methodsAndState.editFlag.value == "create"
            ? "New file has been successfully uploaded."
            : "File has been successfully updated.";
        methodsAndState.setSnackBar({
          dataLoad: true,
          message: message,
          severity: "info",
        });
        methodsAndState.setSelectedItems(new Set([]));
        handleDrawerClose();
      })
      .catch((error) => {
        methodsAndState.setSnackBar({
          dataLoad: true,
          message: error.message,
          severity: "error",
        });
        setFlag(false);
      });

    //  ./Upload data to service
  };

  function footerAction() {
    if (
      methodsAndState.selectedItems.size &&
      !methodsAndState.editFlag.flag &&
      methodsAndState.editFlag.value == "edit"
    ) {
      return (
        <Button
          variant="contained"
          size="small"
          onClick={editHandler}
          startIcon={<EditIcon />}
        >
          EDIT DETAILS
        </Button>
      );
    } else {
      if (flag == false) {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={createFile}
            disabled={isSubmitDisabled}
          >
            SUBMIT
          </Button>
        );
      } else {
        return (
          <Button variant="contained">
            <CircularProgress color={"success"} size="20px" />
          </Button>
        );
      }
    }
  }

  useEffect(() => {
    ApiService.get("tags")
      .then((res) => {
        let tagResponseData = res.data.data;
        if (tagResponseData) {
          tagResponseData = tagResponseData.map((td) => {
            if (td.name) {
              if (td.name.en) {
                // return {label:td.name.en,...td};
                return { label: td.name.en, id: td.id };
              }
            }
          });
          setTagOptionsList(tagResponseData);
        }
      })
      .catch((error) => {
        console.log(error);
        methodsAndState.setSnackBar({
          dataLoad: true,
          message: error.message,
          severity: "error",
        });
      });
      if (
        methodsAndState.selectedItems.size == 1 &&
        methodsAndState.editFlag.value != "create"
      ) {
        const selectedData = methodsAndState.dashboardList.find((item) =>
          methodsAndState.selectedItems.has(item.id)
        );
        let paramsid =
          typeof selectedData.id === "string" && selectedData.id.includes("_")
            ? selectedData.id.split("_")[1]
            : selectedData.id;
        if (methodsAndState?.isRecentFiles) {
          paramsid = selectedData.mediaId;
        }
        ApiService.get("fileDetail", {}, paramsid)
          .then((res) => {
            if (res.data.data) {
              let data = res.data.data;
              // console.debug("FileDashboardKM : fileDetail : data.subCategory : ", data?.subCategory);
              let responseSubCategory=data.subCategory;

              let tempCategoryComponentSelectedValue = [...categoryComponentSelectedValue];
              // console.debug("FileDashboardKM : fileDetail : v : Before tempCategoryComponentSelectedValue : ", tempCategoryComponentSelectedValue);
              responseSubCategory.map(rSC=>{
                // console.debug("FileDashboardKM : fileDetail : rSC : ", rSC);
                if(rSC?.category?.[0]?.categoryName !== undefined){
                  // console.debug("FileDashboardKM : fileDetail : createSlug(rSC?.category?.[0]?.categoryName) : ", createSlug(rSC?.category?.[0]?.categoryName));
                  tempCategoryComponentSelectedValue = updateCategoryComponentSelectedValue(
                    tempCategoryComponentSelectedValue,
                    createSlug(rSC?.category?.[0]?.categoryName),
                    rSC
                  );
                }else{
                  console.error("FileDashboardKM : fileDetail : data.subCategory Something Went Wrong")
                }
                  
              })
              // console.debug("FileDashboardKM : fileDetail : v : Updated : tempCategoryComponentSelectedValue : ", tempCategoryComponentSelectedValue);
              setCategoryComponentSelectedValue([...tempCategoryComponentSelectedValue]);

              responseSubCategory.forEach(element => {
                
              });
              // setCategoriesName(data.subCategory);
              setFileDescriptions(
                data.description != null ? data.description : ""
              );
              setFilename(data.name);
              let tagResponseData = data.tags.map((td) => {
                if (td.name) {
                  if (td.name.en) {
                    return { label: td.name.en, id: td.id };
                  }
                }
              });
              setSelectedTagOptions(tagResponseData);
            }
          })
          .catch((error) => {
            methodsAndState.setSnackBar({
              dataLoad: true,
              message: error.message,
              severity: "error",
            });
          });
      }
  }, []);

  const categoryComponentSelectedValueOnChangeHandler = (
    value,
    e,
    identifier
  ) => {
    let tempCategoryComponentSelectedValue = [
      ...categoryComponentSelectedValue,
    ];
    let categoryIndex = tempCategoryComponentSelectedValue.findIndex((d) => {
      return d?.categoryName === identifier;
    });

    if (categoryIndex < 0) {
      let abc = {
        categoryName: identifier,
        value: { id: value.id, subCategoryName: value.subCategoryName },
      };
      tempCategoryComponentSelectedValue = [
        ...tempCategoryComponentSelectedValue,
        abc,
      ];
    } else {
      tempCategoryComponentSelectedValue[categoryIndex].value = value;
    }
    setCategoryComponentSelectedValue([...tempCategoryComponentSelectedValue]);
  };

  useEffect(() => {
    let componentToRender = null;

    if (categoryListData?.categoryList?.length > 0) {
      componentToRender = categoryListData?.categoryList?.map((category, i) => {
        return (
          <Box key={i} sx={{ mb: 2 }}>
            <ComboBox
              options={category?.subCategory}
              optionLabelKey="subCategoryName"
              optionKey="id"
              multiple={false}
              limitTags={2}
              onChangeHandler={categoryComponentSelectedValueOnChangeHandler}
              name={createSlug(category?.categoryName)}
              identifier={createSlug(category?.categoryName)}
              // value={[]}
              // value={categoryComponentSelectedValue?.filter(d=>d?.name==category?.categoryName ?? d?.selectedValue)?.[0]}
              // disabled={disabledFields ? false : true}
              // disabled={true}
              renderInput={(params) => (
                <TextField
                  // id="enterTags"
                  {...params}
                  label={"Select " + category?.categoryName}
                  placeholder={category?.categoryName}
                />
              )}
            />
          </Box>
        );
      });
    }
    setCategoryComponent(componentToRender);
    // componentToRender;
  }, [categoryListData]);

  const selectedTagOptionOnChangeHandler = (value) => {
    // console.debug("FileDashboardKM : selectedTagOptionOnChangeHandler : value : ",value);
    // selectedTagOptionOnChangeHandler

    // Filter out any blank values
    const filteredValues = value.filter((v) => {
      if (typeof v === "string" || v instanceof String) {
        return v.trim() !== "";
      }
      return v;
    });

    // // Check for tags that exceed the character limit or contain special characters
    const characterLimit = 80;
    const allowedCharactersRegex = /^[a-zA-Z@_\-\s]*$/; // Regex allowing letters, @, _, -, and spaces
    const invalidTags = filteredValues.filter((tag) => {
      if (
        tag.length > characterLimit ||
        !allowedCharactersRegex.test(tag?.label)
      ) {
        return tag;
      }
    });

    if (invalidTags.length > 0) {
      const errorMessage = !allowedCharactersRegex.test(value)
        ? "Tags name can only contain letters, @, _, -, and spaces"
        : "Tags must not exceed 80 characters.";
      setErrorMessage(errorMessage);
      setTagCharacterLimitErr(true);
    } else {
      // No errors, proceed
      setErrorMessage("");
      setTagCharacterLimitErr(false);
      setSelectedTagOptions(filteredValues);
    }
  };

  return (
    <Box>
      <EnhanceDrawer
        headerTitle={
          methodsAndState.editFlag.value == "create"
            ? "Upload File"
            : methodsAndState.editFlag.flag
            ? "Edit File"
            : "View File"
        }
        footerAction={footerAction()}
        drawerActiveStatusChangeHandler={onDrawerActiveStatusChangeHandler}
      >
        <Box
          style={{
            padding: "15px",
            overflow: "auto",
            pt: "var(--equal-hw1)",
            height: "80vh",
          }}
        >
          {methodsAndState.selectedItems.size &&
          methodsAndState.editFlag.value != "create" ? (
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                value={fileName}
                onChange={handleFilenameChange}
                id="outlined-basic"
                label="File Name"
                name="filename"
                variant="outlined"
                disabled={true}
              />
            </Box>
          ) : (
            <FileUpload onChangeHandler={fileChangeHandler} />
          )}
          <Box sx={{ mb: 2 }}>
            <ComboBox
              options={tagOptionList}
              optionKey="id"
              multiple={true}
              freeSolo={true}
              limitTags={2}
              defaultValue={selectedTagOptions}
              onChangeHandler={selectedTagOptionOnChangeHandler}
              renderInput={(params) => (
                <TextField
                  id="enterTags"
                  {...params}
                  label="Tags *"
                  placeholder="Tags"
                />
              )}
            />
            <Typography
              component="span"
              sx={{ fontSize: "12px", color: "#0072E5" }}
            >
              *Please press enter for creating a tag
            </Typography>
            {errorMessage && (
              <Alert variant="none" sx={{ color: "red" }} severity="error">
                {errorMessage}
              </Alert>
            )}
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              value={fileDescriptions}
              onChange={handleFilenameChange}
              label="File Description"
              placeholder="Description Here"
              multiline
              rows={2}
              name="filedescription"
              id="fileDescription"
              sx={{
                "& .MuiInputLabel-formControl:not(.Mui-focused)": {
                  marginTop: "10px",
                },
              }}
              disabled={disabledFields ? false : true}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            {/* {renderCategory()} */}
            {/* {categoryComponent} */}

            {categoryListData?.categoryList?.map((category, i) => {
              // let tempCategoryComponentSelectedValue=[{name:category.categoryName,selectedValue:[]}];
              // setCategoryComponentSelectedValue(tempCategoryComponentSelectedValue);

              return (
                <Box key={i} sx={{ mb: 2 }}>
                  <ComboBox
                    options={category?.subCategory || []}
                    optionLabelKey="subCategoryName"
                    optionKey="id"
                    multiple={false}
                    limitTags={2}
                    onChangeHandler={categoryComponentSelectedValueOnChangeHandler}
                    name={createSlug(category?.categoryName)}
                    identifier={createSlug(category?.categoryName)}
                    defaultValue={getDefaultCategoryValue({...category}, [...categoryComponentSelectedValue])}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Select ${category?.categoryName || ''}`}
                        placeholder={category?.categoryName || ''}
                      />
                    )}
                  />

                </Box>
              );
            })}
          </Box>
        </Box>
      </EnhanceDrawer>
    </Box>
  );
}

export default FileDashboardKM;
