import React from 'react';
import { isLoginAuthServerConsumer,generateAuthServerLoginUrlWithCallback } from 'auth-component';

const AuthenticateRender = ({ children, loginStatus,loginPath, ...rest }) => {

	const currentLoginPath=generateAuthServerLoginUrlWithCallback();
	
	return (isLoginAuthServerConsumer() ?
		<>
			{children}
		</>
		:
		window.location.assign(currentLoginPath)
	);
}
export default AuthenticateRender;
