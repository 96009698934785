import React, { useRef, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  CloseIcon,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
  UploadFileIcon,
} from "../../../utils";
// import WordFile from "../../../assets/images/word.png";
import WordFile from "../../../../assets/images/word.png";
import PDFFile from "../../../../assets/images/pdf.png";
import ExcelFile from "../../../../assets/images/excel.png";
import ImageFile from "../../../../assets/images/picUpload.png";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#2563EB",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#000000",
    fontWeight: "400",
    boxShadow: "-1px 0px 6px #aaa",
    // padding: "15px",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #2563EB",
    borderRadius: 2,
  },
}));

function FileUpload(props) {
  const [inputKey, setInputKey] = useState(0);
  // const allowedExtensions = [];
  const allowedExtensions = [
    ".png",
    ".jpg",
    ".jpeg",
    ".xls",
    ".pdf",
    ".ppt",
    ".pptx",
    ".xlsx",
    ".xlsm",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".doc",
    ".docx",
  ];

  const allowedFileSize = 5;
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [extensionErr, setRxtensionErr] = useState(false);

  const [imagePreviews, setImagePreviews] = useState([]);
  const [message, setMessage] = useState([]);
  const [fileDescriptions, setFileDescriptions] = useState("");
  const [selectedTagArray, setSelectedTagArray] = useState([]);
  const [fileUploadData, setfileUploadData] = useState();
  const [categoriesName, setCategoriesName] = useState([]);
  const progressInfosRef = useRef(null);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [allTags, setAlltags] = useState([]);
  const [fileName, setFilename] = useState("");
  const [flag, setFlag] = useState(false);
  const [tagCharacterLimitErr, setTagCharacterLimitErr] = useState(false);

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];

    setProgressInfos({ val: _progressInfos });
  };
  

  const handleRemove = (index) => {
    let removeFiles = [...selectedFiles];
    removeFiles.splice(index, 1);
    // setState({ uploadedFiles });
    setSelectedFiles([...removeFiles]);

    let removeImagePreviews = [...imagePreviews];
    removeImagePreviews.splice(index, 1);
    setImagePreviews(removeImagePreviews);

    let removeProgressInfos = [...progressInfos.val];
    removeProgressInfos.splice(index, 1);
    setProgressInfos({ val: removeProgressInfos });
  };

  function formatBytes(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Bytes";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  }

  const selectFiles = (event) => {
    const file = event.target.files[0];

    if (file) {
      const invalidFiles = [];
      const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
      const fileExtension = "." + file.name.split(".").pop();
      if (
        allowedExtensions.includes(fileExtension.toLowerCase()) &&
        fileSizeInMB <= allowedFileSize
      ) {
        const imageUrl = URL.createObjectURL(file);
        setSelectedFiles([file]);
        if(props?.onChangeHandler){
          props.onChangeHandler([file]);
        }
        setImagePreviews([imageUrl]);
        setProgressInfos({ val: [] });
        setMessage([]);
        setInputKey(inputKey + 1);
        // setfileUploadData([file]);
        const progressInfo = {
          percentage: 0,
          fileName: file.name,
          fileSize: file.size,
        };
        progressInfosRef.current = {
          val: [progressInfo],
        };
        upload(0, file);
        setRxtensionErr(false);
      } else {
        event.target.value = null; // Clear the input
        setRxtensionErr(
          fileSizeInMB <= allowedFileSize
            ? "File(s) not supported. Upload a supported format."
            : "File size exceeds the limit (" + allowedFileSize + "MB)."
        );
      }
    }
  };

  function handleDragOver() {}

  function handleDrop() {}
  return (
    <Box sx={{ mb: 2 }}>
      <Box>
        <Box>
          <label htmlFor="fileUploadKM">
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0.5rem",
                border: "2px dashed rgba(0, 0, 0, 0.12)",
                borderRadius: "12px",
                backgroundColor: "rgba(201, 201, 201, 0.1)",
                ":hover": {
                  backgroundColor: "#DBEAFE",
                  borderColor: "var(--color-mui-primary-blue)",
                },
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                <Avatar
                  sx={{
                    height: "2.5rem",
                    width: "2.5rem",
                    backgroundColor: "#1976D21F",
                  }}
                >
                  <UploadFileIcon color="primary" />
                </Avatar>
              </Box>
              <Box className="upload-btn-wrapper">
                <span>
                  <Link
                    className="btn"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Click to upload
                  </Link>
                  <input
                    id="fileUploadKM"
                    key={inputKey}
                    type="file"
                    name="myfile"
                    multiple
                    accept={allowedExtensions.join(",")}
                    // accept=".png, .jpg, .jpeg, .xls, .pdf, .ppt, .pptx, .xlsx, .xlsm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .doc, .docx, application/msword"
                    // accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={selectFiles}
                  />
                </span>
                <span> or drag and drop *</span>
              </Box>
              <Box m="1rem 0rem 0rem">
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                  gutterBottom
                >
                  {" "}
                  PNG, JPG, XLS, DOC, PDF and PPT (max. {allowedFileSize}mb)
                </Typography>
              </Box>
              <Box mb="1rem">
                {extensionErr ? (
                  <Alert variant="none" sx={{ color: "red" }} severity="error">
                    {extensionErr}
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
            </Stack>
          </label>
        </Box>
      </Box>
      <Box sx={{ ml: "3rem" }}>
        {progressInfos &&
          progressInfos.val.length > 0 &&
          progressInfos.val.map((progressInfo, index) => (
            <Box key={index} sx={{ my: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={2} md={2}>
                  <Box>
                    {selectedFiles?.[index]?.name.endsWith(".docx") ? (
                      <>
                        <img
                          className="preview"
                          src={WordFile}
                          alt={"image-" + index}
                          key={index}
                        />
                      </>
                    ) : selectedFiles?.[index]?.name.endsWith(".pdf") ? (
                      <img
                        className="preview"
                        src={PDFFile}
                        alt={"image-" + index}
                        key={index}
                      />
                    ) : selectedFiles?.[index]?.name.endsWith(".xlsx") ||
                      selectedFiles?.[index]?.name.endsWith(".xls") ||
                      selectedFiles?.[index]?.name.endsWith(".csv") ? (
                      <img
                        className="preview"
                        src={ExcelFile}
                        alt={"image-" + index}
                        key={index}
                      />
                    ) : (
                      <img
                        height={30}
                        width={30}
                        className="preview"
                        src={ImageFile}
                        alt={"image-" + index}
                        key={index}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={8} md={8}>
                  <Box>
                    <Box>
                      <HtmlTooltip title={progressInfo.fileName}>
                        <span>
                          {progressInfo.fileName.length > 28
                            ? progressInfo.fileName.substring(0, 28) + "..."
                            : progressInfo.fileName}
                        </span>
                      </HtmlTooltip>
                    </Box>
                    <Box sx={{ my: 1 }}>
                      <Stack direction="row" spacing={2}>
                        <span>
                          <li>{formatBytes(progressInfo.fileSize)}</li>
                        </span>

                        <span>
                          {message.length > 0 && (
                            <Box className="alert alert-secondary" role="alert">
                              <li>{message[index]}</li>
                            </Box>
                          )}
                        </span>
                      </Stack>
                    </Box>
                    <Box>
                      {/* <LinearProgress value={progressInfo.percentage} /> */}
                      <LinearProgress
                        variant="determinate"
                        value={100}
                        sx={{
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "red",
                          },
                        }}
                      />
                      {/* <LinearProgress variant="determinate" value={100} /> */}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box sx={{ mb: 1 }}>
                    <IconButton
                      aria-label="upload picture"
                      component="label"
                      onClick={() => handleRemove(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Box>
      <Box></Box>
    </Box>
  );
}

export default FileUpload;
