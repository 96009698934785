import React, { useContext } from "react";
import {
  Box,
  CloseIcon,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "../../../utils";
import DrawerContext from "./DrawerContext";

function DrawerHeader(props) {
  const drawerContextData = useContext(DrawerContext);
  return (
    <Stack sx={{ backgroundColor: "var(--color-lightblue)" }}>
      <Box sx={{ p: ".5rem .1rem" }}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={10}>
            <Box pl="1rem" display="flex">
              <Typography
                variant="body1"
                color="text.primary"
                fontWeight="var(--font-weight-7)"
                display="block"
              >
                {drawerContextData?.headerTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              aria-label="upload picture"
              component="label"
              onClick={(e) =>
                drawerContextData?.toggleDrawerStatusHandler(false)
              }
              color="var(--color-black)"
            >
              <CloseIcon sx={{ color: "var(--color-black)" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}

export default DrawerHeader;
