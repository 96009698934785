import React, { useState } from "react";
import { Box } from "../../../utils";
import DrawerHeader from "./DrawerHeader";
import DrawerFooter from "./DrawerFooter";
import DrawerContext from "./DrawerContext";

function EnhanceDrawer({ children, ...props }) {
  const [drawerOpenStatus, setDrawerOpenStatus] = useState(true);

  function toggleDrawerStatusHandler() {
    setDrawerOpenStatus(!drawerOpenStatus);
    if(props?.drawerActiveStatusChangeHandler ){
      props.drawerActiveStatusChangeHandler(!drawerOpenStatus);
    }
  }

  const drawerContextData = {
    headerTitle: props?.headerTitle,
    footerAction: props?.footerAction,
    toggleDrawerStatusHandler: toggleDrawerStatusHandler,
    drawerOpenStatus,
    drawerOpenStatus,
  };

  return (
    <DrawerContext.Provider value={drawerContextData}>
      <Box sx={{ width: { sm: "40rem" } }}>
        <DrawerHeader />
        {children}
        <DrawerFooter />
      </Box>
    </DrawerContext.Provider>
  );
}

export default EnhanceDrawer;
