import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Card, Stack, Grid, ButtonGroup, Button, Typography, Menu, MenuItem, ListItemText, ListItemIcon, ToggleButton, ToggleButtonGroup, InputLabel, FormControl, Select, Chip, FormControlLabel, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react';
import MyContext from './Mycontext';
import AppsIcon from '@mui/icons-material/Apps';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { userPermissions } from './Permission/required.permission';
import Can from './Permission/Can';
import { useSelector } from 'react-redux';
import DashboardFilter from './DashboardFilter';
import gridview from '../../assets/images/KM-Manager/gridview.svg';
import tabularview from '../../assets/images/KM-Manager/tabularview.svg';

const DashboardTools = () => {
    const methodsAndState = useContext(MyContext);
    const userPermissionsListRedux = useSelector((state) => (state.permission));
    const searchList = useSelector((state) => (state.search));
    const [userPermissionsList, setUserPermissionsList] = useState([]);

    const [alignment, setAlignment] = React.useState('left');
    const currentRoute = useSelector((state) => (state.routing.currentRoute));

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const [alignmentValue, setAlignmentValue] = React.useState('left');

    const handleAlignmentValue = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    useEffect(() => {
        if (userPermissionsListRedux.permissionList) {
            setUserPermissionsList(userPermissionsListRedux.permissionList)
        }
    }, [userPermissionsListRedux])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const isMediumOrLarger = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const buttonSX = {
        '&': {
            color: '#000000', border: "1px solid rgba(145, 158, 171, 0.24)", minWidth: "40px", padding: "5px 10px", '&:hover': {
                color: '#000000', border: "1px solid rgba(145, 158, 171, 0.24)"
            },
        },
        '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#000000' }
    }
    return (
        <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
            {/* FIlter Feature is commented */}
            {/* {!methodsAndState.tagsFlag ? "" :
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    {currentRoute == '/' ? <Box>
                            <Button
                                // sx={buttonSX}
                                variant="outlined"
                                id="categoryFilter"
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                disabled={methodsAndState.selectedItems.size > 0 || searchList.searchValue != ""}
                                sx={{...buttonSX,'&.Mui-disabled': {color: "rgba(145, 158, 171, 0.8)",
                                    backgroundColor:' rgba(145, 158, 171, 0.24)', opacity: 0.3}}}
                                >
                                <FilterListIcon />
                            </Button>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{ '& .MuiMenu-list': { padding: '1rem' }, '& .MuiPopover-paper': { marginTop: '3rem', marginLeft: { lg: '-2rem' } }, width: '100%' }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            // disableRipple={methodsAndState.selectedItems.size > 0}
                        >
                            <DashboardFilter />
                        </Menu>
                    </Box> : null}

                </Box>} */}
            <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
                <ToggleButtonGroup
                    value={alignment}
                    id='gridTableView'
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    sx={{ height: '2.2rem' }}
                >
                    <ToggleButton value="left" aria-label="left aligned" onClick={methodsAndState.handleGridViewClick}>
                        {/* <AppsIcon /> */}
                        <img src={gridview} alt='gridview' />
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered" onClick={methodsAndState.handleTreeViewClick}>
                        {/* <TableRowsIcon /> */}
                        <img src={tabularview} alt='gridview' />
                    </ToggleButton>
                </ToggleButtonGroup>

            </Box>
            {!methodsAndState.tagsFlag ? "" :
                <Can
                    userPermissions={userPermissionsList}
                    requirePermissions={[userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_CREATE, userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_CREATE]}
                    yes={
                        currentRoute == '/' ? <Box>
                            {isMediumOrLarger ? (
                                <Button
                                    id='folderFileOption'
                                    variant="contained"
                                    size="medium"
                                    startIcon={<AddIcon />}
                                    aria-controls={methodsAndState.open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={methodsAndState.open ? 'true' : undefined}
                                    onClick={methodsAndState.handleClick}
                                    disabled={methodsAndState.selectedItems.size > 0 || searchList.searchValue != ""}>
                                    NEW
                                </Button>
                            ) : (
                                <Button
                                    id='folderFileOption'
                                    variant="contained"
                                    size="medium"
                                    aria-controls={methodsAndState.open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={methodsAndState.open ? 'true' : undefined}
                                    onClick={methodsAndState.handleClick}>
                                    <AddIcon />
                                </Button>
                            )}
                            <Menu id="basic-menu"
                                anchorEl={methodsAndState.anchorEl}
                                open={methodsAndState.open}
                                onClose={methodsAndState.handleClose}
                                // sx={{left:{lg:"1172px"}}}
                                sx={{ marginLeft: '-2rem' }}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}>
                                <Can
                                    userPermissions={userPermissionsList}
                                    requirePermissions={[userPermissions.KM, userPermissions.KM_FOLDER, userPermissions.KM_FOLDER_CREATE]}
                                    yes={<MenuItem
                                        id='addFolder'
                                        onClick={() => methodsAndState.openDialogPublish('folderDialog')}>
                                        <ListItemIcon><FolderIcon /></ListItemIcon>
                                        <ListItemText>Folder</ListItemText>
                                    </MenuItem>
                                    } />
                                <Can
                                    userPermissions={userPermissionsList}
                                    requirePermissions={[userPermissions.KM, userPermissions.KM_FILE, userPermissions.KM_FILE_CREATE]}
                                    yes={
                                        <MenuItem
                                            id='uploadFile'
                                            onClick={() => methodsAndState.openDrawerPublish('createFile')}>
                                            <ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
                                            <ListItemText>Upload File</ListItemText>
                                        </MenuItem>
                                    } />
                            </Menu>
                        </Box> : null} />
            }
        </Box>
    );
};

export default DashboardTools;