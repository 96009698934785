import { Autocomplete, Button, Chip, CircularProgress, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';

/*  Props are
1) - options
2) - onChangeHandler
3) - renderInput
4) - multiple - default false
5) - optionKey - to tell which key identify unique in array of object
6) - optionLabelKey - to tell which key to show the data , default is label
7) - disabled
8) - limitTags - to limit the display of tag, default is -1 to disable this funtionality
9) - required - default is false
10) - freeSolo - default false // to enable tag
11) - defaultValue - default value is [];
12) - identifier // Custom props to send data 
*/

function ComboBox({ multiple = false, ...props }) {
    // console.debug("ComboBox : props : ", props);
    const [comboBoxOptions, setComboBoxOptions] = useState([]);
    const [comboBoxSelectedValue, setComboBoxSelectedValue] = useState(multiple ? [] : null);
    const [defaultValue, setDefaultValue] = useState(multiple ? [] : null);
    const [open, setOpen] = useState(false);
    const loading = open && comboBoxOptions.length === 0;

    function getLabelOptionUsingKey(option) {
        // console.debug("ComboBox : getLabelOptionUsingKey : option : ", option);
        // console.debug("ComboBox : getLabelOptionUsingKey : comboBoxOptions : ", comboBoxOptions);
        // console.debug("ComboBox : getLabelOptionUsingKey : comboBoxSelectedValue : ", comboBoxSelectedValue);
        if (props.optionLabelKey !== undefined && props.optionLabelKey !== null ) {
            if(props?.freeSolo !== undefined && props.freeSolo == true && (typeof option === 'string' || option instanceof String)){
                return option;
            }else{
                return option[props.optionLabelKey];
            }
        } else {
            if(props?.freeSolo !== undefined && props.freeSolo == true && (typeof option === 'string' || option instanceof String)){
                return option;
            }else{
                return option.label;
            }
        }
    }
    useEffect(() => {
        if (props?.options) {
            setComboBoxOptions(props.options);
        }
    }, [props?.options]);

    useEffect(() => {
        if (props?.defaultValue) {
            setDefaultValue(props.defaultValue);
            setComboBoxSelectedValue(props.defaultValue);
            // It is commented due re-rendering issue in KM file Upload
            // if (props?.onChangeHandler) {
            //     props.onChangeHandler(props.defaultValue);
            // }
        }
    }, [props.defaultValue]);

    const handleDefaultChange = (event, value) => {
        // Default change handler does nothing
    };

    const renderDefaultInput = (params) => (
        <TextField {...params}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </Fragment>
                ),
            }}
            label={props.headKey} variant="outlined" />
    );

    const renderTags = (tagValue, getTagProps) => {
        if (multiple) {
            return tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index }); // Destructure key out of tagProps
                return (
                    <Chip
                        label={getLabelOptionUsingKey(option)}
                        key={option[props.optionKey] || index} // Ensure each key is unique
                        {...tagProps}
                    />
                );
            });
        }
        return null;
    };

    const onChangeHandler = (e, item) => {
        // console.debug("FileDashboardKM : onChangeHandler : e : ", e);
        setComboBoxSelectedValue(item);
        if (props?.onChangeHandler) {
            props.onChangeHandler(item,e,props?.identifier);
        }
    };

    const renderProps=()=>{
        let tempRenderProps={};
        if(props?.name !== undefined){
            tempRenderProps.id=props.name
            tempRenderProps.name=props.name
        }
        return tempRenderProps;
    }
    return (
        <Autocomplete
            // id="combo-box-demo"
            size='small'
            disableClearable
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            loading={loading}
            disabled={props?.disabled ? props.disabled : false}
            getOptionKey={(option) => option[props.optionKey]}
            // className="w-100 selectBox fs-4 customInputfeilds mt-1"
            //  variant="outlined"
            {...renderProps()}
            limitTags={props?.limitTags ? props.limitTags : -1}
            required={props?.required ? props.required : false}
            sx={{
                '&.MuiFormLabel-root.Mui-focused': {
                    color: "#000",
                    padding: "10px",
                },
                '&.MuiAutocomplete-groupLabel': {
                    padding: "8px 10px 10px 10px",

                }
            }}
            // submitJob
            renderTags={renderTags}
            defaultValue={defaultValue}
            multiple={multiple !== undefined && multiple == true ? true : false}
            freeSolo={props?.freeSolo !== undefined && props.freeSolo == true ? true : false}
            getOptionLabel={(option) => getLabelOptionUsingKey(option)}
            isOptionEqualToValue={(option, value) => option[props.optionKey] === value[props.optionKey]}
            options={comboBoxOptions}
            onChange={onChangeHandler}
            value={comboBoxSelectedValue}
            renderInput={props?.renderInput || renderDefaultInput}
        />
    );
}

export default ComboBox;

// // Usage example in another component
// const App = () => {
//     const options = [
//         { label: 'Demo 1', id: 1 },
//         { label: 'Demo 2', id: 2 },
//         { label: 'Demo 3', id: 3 },
//     ];
//     const defaultValue = { label: 'Demo 2', id: 2 };

//     return (
//         <div>
//             <ComboBox
//                 options={options}
//                 value={defaultValue}
//                 onChangeHandler={(value) => console.log('Selected:', value)}
//                 optionKey="id"
//                 multiple={true} // Change to false to test single selection
//             />
//         </div>
//     );
// };

// export default App;
