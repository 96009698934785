import React, { useContext, useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid, Button, useMediaQuery, IconButton, Popover } from '@mui/material'
import MyContext from './Mycontext';
import Skeleton from '@mui/material/Skeleton';
import Checkbox from '@mui/material/Checkbox';
import checkboxHelper from './checkboxHelper';
import TableComponent from '../../features/TableComponent/TableComponent'
import Chip from '@mui/material/Chip';
import DashboardKmTools from './DashboardKmTools';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ImageType from './ImageType';
import { useSelector, useDispatch } from 'react-redux';
import { setPageNo, setScrollFlag } from '../../features/dashboardData/dashboardData';
import InfiniteScroll from 'react-infinite-scroll-component';
import PushPinIcon from '@mui/icons-material/PushPin';
import unpin from '../../assets/images/KM-Manager/unpin.svg';
import MenuBar from './menuBar';
import PinUnpin from './PinUnpin';
import menuDown from '../../assets/images/KM-Manager/menu-down.svg';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import throttle from '../../utils/throttle';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2563EB',

  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000000',
    fontWeight: '400',
    boxShadow: '-1px 0px 6px #aaa',
    // padding: "15px",
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #2563EB',
    borderRadius: 2,
  },
}));

const DashboardTreeView = ({isTrash, isPinned}) => {
  console.log({isPinned})
  const [showCode, setShowCode] = useState(false);
  const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
  const [headerIntermidiateStatus, setHeaderIntermidiateStatus] = useState(false);
  const [checkedData, setCheckedData] = useState()
  // const [checkboxGridHelper, setCheckboxGridHelper] = useState(checkboxHelper());
  const methodsAndState = useContext(MyContext);
  const loading = useSelector((state) => state.loader)
  const dashboardScroll = useSelector((state) => (state.dashboardScroll));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pinUnpinHandler } = PinUnpin();
  const [columns, setColumns] = useState([]); // Your columns state

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverContent, setPopoverContent] = useState("");
  const [popOverContentDesc, setPopoverContentDesc] = useState("");
  // const debaunceThrottle = throttle();
  const [disablePinBtn, setDisablePinBtn] = useState(false);
  
  // useEffect(() => {
  //   if(disablePinBtn){
  //       setTimeout(() => {
  //           setDisablePinBtn(false);
  //       }, 1000);
  //   }
  // }, [disablePinBtn])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget ?? event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  React.useEffect(() => {
    if (methodsAndState.dashboardList.length)
      methodsAndState.checkboxGridHelper.init(
        methodsAndState.dashboardList,
        methodsAndState.setSelectedItems,
        setHeaderCheckStatus,
        setShowCode,
        setHeaderIntermidiateStatus
      )
  }, [methodsAndState.dashboardList]);
  const checkBoxHandler = (data) => {
    methodsAndState.checkboxGridHelper.checkboxListener(data.id)
    setCheckedData(data)
    methodsAndState.setSelectedId({ id: data.id, value: "checkbox", type: data.type, name: data.name, delete: "notall" })
  }
  useMemo(() => {
    if (methodsAndState.selectedItems.size == 0) {
      methodsAndState.checkboxGridHelper.clearSelection()
    }
  }, [methodsAndState.selectedItems])

  useEffect(() => {
    methodsAndState.checkboxGridHelper.setIntermidiateStatus(true);
  }, [])

  const pinUnpinSingleHandler = (e, data, noClear) => {
    e.stopPropagation();
    setDisablePinBtn(true);
    selectionCheckboxClear(data, noClear)
    pinUnpinHandler(methodsAndState.dashboardList, methodsAndState.selectedItems, data.pinned ? "Unpin" : "Pin", methodsAndState.setSelectedItems, methodsAndState.setSnackBar, methodsAndState.setDashboardList, setDisablePinBtn);
    if(isPinned && data.deletedAt){
      methodsAndState.setSnackBar({ dataLoad: true, message: "Removed from Pinned Section", severity: "info" })
      // pinUnpinHandler(methodsAndState.dashboardList, methodsAndState.selectedItems, data.pinned ? "Unpin" : "Pin", new Set([data.pinnedId]), methodsAndState.setSnackBar, methodsAndState.setDashboardList);
    } else {
    }
  }
  const selectionCheckboxClear = (data, noClear) => {
    if (!noClear) {
      methodsAndState.checkboxGridHelper.clearSelection()
      methodsAndState.checkboxGridHelper.checkboxListener(data.id);
    }
    methodsAndState.selectedItems.clear()
    methodsAndState.selectedItems.add(data.id)
  }

  const openFolder = (data) => {
    if(isPinned && data.disable){
      return;
    }
    if (data.type === 'directory') {
      const paramsid = data?.id.includes('_') ? data?.id.split('_')[1] : data?.id;
      navigate('/folder/' + paramsid);
      methodsAndState.setSelectedId({ id: data.id, value: 'folder' });
      const state = [...methodsAndState.breadCrumb, data];
      methodsAndState.setBreadCrumb(state);
      methodsAndState.setSelectedItems(new Set([]))
      dispatch(setScrollFlag(false))
      dispatch(setPageNo(0))
    } else {
      methodsAndState.downloadFile('open')
    }
  };
  
  function formatBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  }
  const buttonSX = {
    '&': { color: '#00000087', padding: '0px' },
    '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087' }
  }
  const handleMoreTag = () => {
    navigate('/tags');
  };
  // const isMobileView = window.innerWidth < 768; // Adjust the breakpoint as needed

  const options = { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true };

  const desktopColumns = [
    {
      // Define all your columns for the desktop view

      id: 'select', label: '', width: 30, headerTemplate: () => {
        return (<>
          <Checkbox onClick={methodsAndState.checkboxGridHelper.headerCheckboxListener} checked={headerCheckStatus} indeterminate={headerIntermidiateStatus} />
        </>)
      }, headerName: 'Type', flex: 1, template: (key) => {
        return (<>
          <Box>
            <Checkbox
              disabled={key?.disable}
              onClick={() => checkBoxHandler(key)}
              checked={methodsAndState.selectedItems.has(key.id)}
            />

          </Box>
        </>)
      }
    },
    {
      id: 'name', field: 'name', width: 150, flex: 1, headerTemplate: () => {
        return (<Box sx={{ display: "flex" }} >
          Name
          {!isPinned && (<img src={menuDown} className='marginLeft' alt='sort-image' onClick={() => methodsAndState.sortByNameType('name')} />)}
        </Box>)
      }, template: (row) => {
        return (<Box onClick={(event) => {
          if(isPinned && row.deletedAt){
            setPopoverContent("This " + (row.type === 'directory' ? 'folder' : 'file') + ' was deleted by ' + row.deletedBy.charAt(0).toUpperCase() + row.deletedBy.slice(1));
            setPopoverContentDesc(' on ' + new Date(row.deletedAt).toLocaleString("en-US", options));
            handleClick(event);
            return;
          }
        }}><Box className={row.disable ? "disabled-container" : ""} sx={{ display: "flex", height: 30, textWrap: "nowrap", cursor: "pointer", alignItems: "center", justifyContent: "space-between", '&.disabled-container': {pointerEvents: 'all'} }}>
          <Box onClick={() => openFolder(row)} sx={{ display: "flex", height: 30, textWrap: "nowrap", cursor: "pointer", alignItems: "center" }}>
            {/* <Box sx={{minWidth: {xl: '45.68px', lg: '44.68px', md: '44.68px', sm: '44.68px', xs: '44.68px'}}}> */}
              <ImageType data={row} />
            {/* </Box> */}
            <HtmlTooltip title={row?.name} arrow>
              <Box sx={{ alignSelf: 'center', padding: '0 5px', marginLeft: "11px" }}>
                {row?.name && row.name.length > 20 ? row.name.slice(0, 20) + '...' : row?.name}
              </Box>
            </HtmlTooltip>

          </Box>
          {row?.name ? <Box sx={{ ml: '1rem', mt: '0.2rem' }} onClick={() => selectionCheckboxClear(row)} >
            <HtmlTooltip title={(isPinned && row.disable) ? 'Remove from pinned section' : row.pinned ? 'Unpin' : 'Pin'}>
              <IconButton onClick={(e) => {
                // debaunceThrottle.throttle(() => {
                  pinUnpinSingleHandler(e, row, true)
                // }, 1000)
              }} 
              disabled={disablePinBtn}
              sx={buttonSX} aria-label="pinunpin">
                {(isPinned && row.disable) ? <DeleteOutlineIcon /> : row.pinned || window.location.pathname == "/pinned" ? <img src={unpin} /> : <PushPinIcon />}
              </IconButton></HtmlTooltip>
          </Box> : null}
        </Box></Box>)

      }

    },
    {
      id: 'type', headerName: 'Type', minWidth: 70, flex: 1, headerTemplate: () => {
        return (<Box sx={{ display: "flex" }} >
          Type
          {!isPinned && (<img src={menuDown} className='marginLeft' alt='sort-image' onClick={() => methodsAndState.sortByNameType('type')} />
          )}
        </Box>)
      },
    },
    {
      id: 'tags',
      label: 'Tags',
      headerName: 'Tags',
      minWidth: 130,
      flex: 1,
      template: (row) => {
        const tags = row.tags || [];
        const visibleTags = tags.slice(0, 2);
        const remainingTags = tags.slice(2);

        console.log({tags})
        return (
          <Box onClick={handleMoreTag}>
            {visibleTags.map((tag, index) => (
              <React.Fragment key={(tag?.id ?? (tag + index))} >
                <Chip
                  // className={tag.name.en.length > 55 ? 'wordWrap' : ''}
                  sx={{ margin: '5px', borderRadius: '8px', cursor: 'pointer' }}
                  label={(tag?.name?.en ?? tag)}
                />
                {/* {tag.name.en} */}
                {index < visibleTags.length - 1 && ' '}
              </React.Fragment>
            ))}
            {remainingTags.length > 0 && (
              <HtmlTooltip title={remainingTags.map((tag) => (tag?.name?.en ?? tag)).join(', ')} arrow>
                <span sx={{ mx: 2 }}>
                  <Button >{`+${remainingTags.length}`}</Button>
                </span>
              </HtmlTooltip>
            )}
          </Box>
        );
      },
    },
    {
      id: 'modifyBy', label: 'Modified By', headerName: 'Modified By', minWidth: 130, flex: 1, template: (row) => {
        return (<>
          <Box sx={{ display: "flex", height: 30, textWrap: "nowrap", alignItems: "center" }}>
            {((row?.modifyBy?.name ? row?.modifyBy?.name : '') + (row?.modifyBy?.lastName ? (' ' + row?.modifyBy.lastName) : '' ))}
          </Box>

        </>)
      }
    },
    {
      id: 'size', label: 'File Size', headerName: 'File Size', minWidth: 130, flex: 1, template: (row) => {
        return (<>
          <Box sx={{ display: "flex", height: 30, textWrap: "nowrap", alignItems: "center" }}>
            <HtmlTooltip title={row?.size} arrow>
              <Box sx={{ alignSelf: 'center', padding: '0 5px' }}>
                {row.size != null ? formatBytes(row.size) : "--"}
              </Box>
            </HtmlTooltip>
          </Box>

        </>)

      }
    },
    {
      id: '3dots', label: '', headerName: '', minWidth: 130, flex: 1, template: (row) => {
        return (<Box onClick={() => selectionCheckboxClear(row)}>
          {!row?.disable ? <MenuBar data={checkedData} value={true} /> : null}
        </Box>)

      }

    },
  ];

  // Define your columns for both mobile and desktop views
  const mobileColumns = [
    {
      // Only show 'name' column for mobile view
      // Define all your columns for the desktop view

      id: 'select', label: '', width: 30, headerTemplate: () => {
        return (<>
          <Checkbox onClick={methodsAndState.checkboxGridHelper.headerCheckboxListener} checked={headerCheckStatus} indeterminate={headerIntermidiateStatus} />
        </>)
      }, headerName: 'Type', flex: 1, template: (key) => {
        return (<>
          <Box>
            <Checkbox
              disabled={key?.disable}
              onClick={() => checkBoxHandler(key)}
              checked={methodsAndState.selectedItems.has(key.id)}
            />

          </Box>
        </>)
      }
    },
    {
      id: 'name', field: 'name', width: 150, flex: 1, headerTemplate: () => {
        return (<Box sx={{ display: "flex" }} >
          Name
          <img src={menuDown} className='marginLeft' alt='sort-image' onClick={() => methodsAndState.sortByNameType('name')} />
        </Box>)
      }, template: (row) => {
        return (<Box onClick={(event) => {
          if(isPinned && row.deletedAt){
            setPopoverContent("This " + (row.type === 'directory' ? 'folder' : 'file') + ' was deleted by ' + row.deletedBy.charAt(0).toUpperCase() + row.deletedBy.slice(1));
            setPopoverContentDesc(' on ' + new Date(row.deletedAt).toLocaleString("en-US", options));
            handleClick(event);
            return;
          }
        }}><Box className={row.disable ? "disabled-container" : ""} sx={{ display: "flex", textWrap: "nowrap", cursor: "pointer", alignItems: "flex-start", justifyContent: "space-between", flexDirection: 'column', '&.disabled-container': {pointerEvents: 'all'} }}>
          <Box onClick={() => openFolder(row)} sx={{ display: "flex", textWrap: "nowrap", cursor: "pointer", alignItems: "flex-start" }}>
            <ImageType data={row} />
            <HtmlTooltip title={row?.name} arrow>
              <Box sx={{ alignSelf: 'center', padding: '0 5px', fontSize: '12px' }}>
                {row?.name && row.name.length > 20 ? row.name.slice(0, 20) + '...' : row?.name}
              </Box>
            </HtmlTooltip>

          </Box>

          {/* Type */}
          <Box sx={{ display: 'flex' }} >
            <Box className="mobileViewBox" sx={{ marginRight: '10px' }}>
              Modified By:
              <Typography variant='span' className='mobileViewStyle' >{((row?.modifyBy?.name ? row?.modifyBy?.name : '') + (row?.modifyBy?.lastName ? (' ' + row?.modifyBy?.lastName) : '')) }</Typography>
            </Box>
            <Box className="mobileViewBox" sx={{ marginRight: '10px' }}>
              Size:
              <Typography variant='span' className='mobileViewStyle' >{row.size != null ? formatBytes(row.size) : "--"}</Typography>
            </Box>
            {row?.name ? <Box onClick={() => selectionCheckboxClear(row)} >
              <HtmlTooltip title={(isPinned && row.disable) ? 'Remove from pinned section' : row.pinned ? 'Unpin' : 'Pin'}>
                <IconButton onClick={(e) => {
                  // debaunceThrottle.throttle(() => {
                    pinUnpinSingleHandler(e, row, true)
                  // }, 1000)
                }} 
                disabled={disablePinBtn}
                sx={buttonSX} aria-label="pinunpin">
                  {(isPinned && row.disable) ? <DeleteOutlineIcon /> : row.pinned || window.location.pathname == "/pinned" ? <img src={unpin} /> : <PushPinIcon />}
                </IconButton></HtmlTooltip>
            </Box> : null}
          </Box>


        </Box></Box>)

      }

    },
    {
      id: 'tags',
      label: 'Tags',
      headerName: 'Tags',
      minWidth: 130,
      flex: 1,
      template: (row) => {
        const tags = row.tags || [];
        const visibleTags = tags.slice(0, 2);
        const remainingTags = tags.slice(2);

        return (
          <Box onClick={handleMoreTag}>
            {visibleTags.map((tag, index) => (
              <React.Fragment key={(tag?.id ?? (tag + index))} >
                <Chip
                  sx={{
                    margin: '5px', borderRadius: '8px', cursor: 'pointer', '& .MuiChip-label': {
                      fontSize: '12px',
                    }
                  }}
                  label={(tag?.name?.en ?? tag)}
                />
                {index < visibleTags.length - 1 && ' '}
              </React.Fragment>
            ))}
            {remainingTags.length > 0 && (
              <HtmlTooltip title={remainingTags.map((tag) => (tag?.name?.en ?? tag)).join(', ')} arrow>
                <span sx={{ mx: 2 }}>
                  <Button >{`+${remainingTags.length}`}</Button>
                </span>
              </HtmlTooltip>
            )}
          </Box>
        );
      },
    }, {
      id: '3dots', label: '', headerName: '', minWidth: 130, flex: 1, template: (row) => {
        return (<Box sx={{ textAlign: "right" }} onClick={() => selectionCheckboxClear(row)}>
          {!row?.disable ? <MenuBar data={checkedData} value={true} /> : null}
        </Box>)
      }
    },
  ];

  // const mobileColumns = desktopColumns.filter((col) => ['name', 'tags', '3dots'].includes(col.id));
  const isMobileView = window.innerWidth < 933; // Adjust the breakpoint as needed

  const rows = methodsAndState.dashboardList;
  console.log({rows})

  const fetchData = () => {
    // if (methodsAndState.metaData.last_page > methodsAndState.metaData.current_page) {
    if (methodsAndState?.metaData?.last_page > methodsAndState?.metaData?.current_page) {
      dispatch(setScrollFlag(true))
      methodsAndState.apiCallingDashboard(dashboardScroll.pageNo, true)
    }
  }

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const mtValue = isSmallScreen ? '3rem' : '';
  return (

    <Box >
      {loading.loaderValue ? (
        <>
          <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
          <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
          <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
        </>
      ) : (
        <Box sx={{ position: 'relative' }}>
          {
            <InfiniteScroll
              dataLength={methodsAndState.dashboardList.length}
              next={fetchData}
              hasMore={true} // Replace with a condition based on your data source
              loader={(methodsAndState?.metaData?.last_page > methodsAndState?.metaData?.current_page) ? <Grid container spacing={2} >
                {[1, 2, 3, 4].map((d, key) => {
                  return (
                    <Box key={key}>
                      <Skeleton variant="rounded" sx={{ my: '1rem' }} width={1000} height={40} />
                    </Box>
                  );
                })}
              </Grid> : false}
            >
              {methodsAndState.dashboardList.length ?
                <Grid container >
                  <Grid item xs={12}>
                    <Box sx={{ '& table thead tr th:first-of-type': { zIndex: '1' } }}>
                      <TableComponent data={rows} columns={isMobileView ? mobileColumns : desktopColumns} />
                    </Box>
                  </Grid>
                </Grid>
                : <Typography sx={{ textAlign: "center", width: "100%" }} >No Data Found</Typography>
              }
            </InfiniteScroll>
          }
        </Box>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, whiteSpace: 'pre-wrap' }}>
          <Box>
            <Typography>{popOverContent}</Typography>
          </Box>
          <Box>
            <Typography variant="caption">{popOverContentDesc}</Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DashboardTreeView;
